import {Box, CircularProgress, Grid} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {
  fetchfromPoolCreator,
  fetchPoolCreator,
  fetchSLMCreator,
  fetchSalesTransferDataCreator,
  fetchYearlyQuartersCreator,
  inactiveNavbarDropdownDivisionCreator,
  incentiveActions,
  fetchSalesTransferGoBtnCreator,
  inactiveNavbaFilterIconCreator,
} from '../../../redux/slice';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';

import Dropdown from '@app/components/dropdown';
import {Styles} from './style';
import {appSelector} from '@app/store/selectors';
import {incentiveSelector} from '../../../redux/selectors';
import MultiSelectDropDown from '@app/components/multiSelectDropDown';
import If from '@app/components/If';
import {API_PATH} from '@app/common/constants';
import {ConfirmationDialog} from '@app/components/widgets/ConfirmationDialog';
import {downloadExcelFile} from '@app/services/download';
import { CustomButton } from '@app/screens/Styles/style';

let tempArr: any[] = [];
let quaterId: number[] = [];
let gfpId: number[] = [];
let tgfpId: number[] = [];
let prevSearchQuery = '';

function FilterHeader() {
  const [division, setDivision] = useState<
    {label: string; value: number} | undefined
  >(undefined);
  const [quarter, setQuarter] = useState<
    {label: string; id: number} | undefined
  >(undefined);
  const [toPool, setToPool] = useState<any>([]);
  const [grpOfToPool, setGrpOfToPool] = useState<
    {label: string; value: number; isSelected: boolean} | undefined
  >(undefined);
  const [fromPool, setFromPool] = useState<any>([]);
  const [singleValueFromPool, setSingleValueFromPool] = useState<
    {label: string; value: number; isSelected: boolean} | undefined
  >(undefined);
  const [singleValueToPool, setSingleValueToPool] = useState<
    {label: string; value: number; isSelected: boolean} | undefined
  >(undefined);
  const [grpOfFromPool, setGrpOfFromPool] = useState<
    {label: string; value: number; isSelected: boolean} | undefined
  >(undefined);
  const [slm, setSLM] = useState<{label: string; value: number} | undefined>(
    undefined,
  );
  const [togroupOfPoolListArr, setTogroupOfPoolListArr] = useState<any>([]);
  const [fromgroupOfPoolListArr, setFromgroupOfPoolListArr] = useState<any>([]);
  const [isGoBtnDisble, setIsGoBtnDisble] = useState(true);
  const [quaterListArr, setQuaterListArr] = useState<any>([]);
  const [divisionListId, setDivisionListId] = useState<any>([]);
  const [quaterListId, setQuaterListId] = useState<any>([]);
  const [staffPositionIdList, setStaffPositionIdList] = useState<any>([]);
  const [selectedDivisition, setSelectedDivisition] = useState<any>([]);
  const [selectedQuater, setSelectedQuater] = useState<any>([]);
  const [selectedSlm, setSelectedSlm] = useState<any>([]);
  const [showDownloadConfirmation, setShowDownloadConfirmation] =
    useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [resetSlmDropdown, setResetSlmDropdown] = useState<false | 1>(false);

  const divisionList = useSelector(incentiveSelector.getDivisionList());
  const quarterList = useSelector(incentiveSelector.getQuartetList());
  const slmList = useSelector(incentiveSelector.getSLMData());
  const toPoolList = useSelector(incentiveSelector.getPoolData());
  const grpOfToPoolList = useSelector(incentiveSelector.getGrpOfPoolData());
  const userInfo = useSelector(appSelector.getUserInfo(), shallowEqual);
  const adminStaffPositionMasterId = userInfo.id;
  const search = useSelector(incentiveSelector.getNavSearch(), shallowEqual);
  const isActive = useSelector(incentiveSelector.getStatus, shallowEqual);
  const auditTrailData = useSelector(
    incentiveSelector.getAuditTrailData(),
    shallowEqual,
  );
  const refetchSalesTransfer = useSelector(
    incentiveSelector.getRefetchSalesTransfer(),
    shallowEqual,
  );

  const isFilterIconStatus = useSelector(
    incentiveSelector.getNavFilterIcon(),
    shallowEqual,
  );

  const fromPoolFilterData = useSelector(
    incentiveSelector.getFilterFromPoolData(),
    shallowEqual,
  );
  const toPoolFilterData = useSelector(
    incentiveSelector.getFilterToPoolData(),
    shallowEqual,
  );
  const toGroupOfPoolData = useSelector(
    incentiveSelector.getToGroupOfPoolList(),
  );
  const salesTransferData = useSelector(
    incentiveSelector.getSalesTransferData(),
    shallowEqual,
  );

  const salesTransferExcelUploadData = useSelector(
    incentiveSelector.getSalesTransferExcelUploadData(),
    shallowEqual,
  );

  const salesTransferExcelTemplateDownload = useSelector(
    incentiveSelector.getSalesTransferExcelTemplateDownloadData(),
    shallowEqual,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if(divisionList?.length && !divisionListId?.length && isActive === 1) {
      const allDivisionids = divisionList.map((d: any) => d.value);
      dispatch(fetchSLMCreator(allDivisionids));
    }
  }, [divisionList, isActive]);

  useEffect(() => {
    if(slmList?.length && !staffPositionIdList?.length && isActive === 1) {
      fetchDownloadGroupOfPools([]);
    }
  }, [slmList, isActive]);

  useEffect(() => {
    setTogroupOfPoolListArr(toGroupOfPoolData);
  }, [toGroupOfPoolData]);

  useEffect(() => {
    setFromgroupOfPoolListArr(grpOfToPoolList);
  }, [grpOfToPoolList]);

  useEffect(() => {
    dispatch(
      inactiveNavbarDropdownDivisionCreator({
        adminStaffPositionMasterId: adminStaffPositionMasterId,
      }),
    );
    dispatch(fetchYearlyQuartersCreator());
  }, []);

  useEffect(() => {
    dispatch(fetchSalesTransferGoBtnCreator({val: 0}));
  }, []);

  useEffect(() => {
    if(salesTransferExcelUploadData?.excelFile && salesTransferExcelUploadData?.filters && !Object.keys(salesTransferExcelUploadData?.filters).length) {
      dispatch(incentiveActions.setSalesTransferExcelUploadData({
        ...salesTransferExcelUploadData,
        filters: getFiltersData(),
      }))
    }
  }, [salesTransferExcelUploadData]);

  useEffect(() => {
    if(salesTransferExcelTemplateDownload) {
      console.log("Download Excel Template Called")
      dispatch(incentiveActions.setSalesTransferExcelTemplateDownload(false));
      handleDownloadExcelTemplate();
    }
  }, [salesTransferExcelTemplateDownload]);

  useEffect(() => {
    if (
      (grpOfFromPool && grpOfToPool) ||
      (grpOfFromPool && singleValueToPool) ||
      (singleValueFromPool && grpOfToPool) ||
      (singleValueFromPool && singleValueToPool)
    ) {
      setIsGoBtnDisble(false);
    } else {
      setIsGoBtnDisble(true);
    }

    if (!division) {
      setIsGoBtnDisble(true);
    }
  }, [
    toPool,
    fromPool,
    grpOfFromPool,
    grpOfToPool,
    singleValueToPool,
    singleValueFromPool,
  ]);

  useEffect(() => {
    if (isFilterIconStatus) {
      // filterHandler( '', auditTrailData)
    }
  }, [auditTrailData]);

  useEffect(() => {
    
    if (quarterList.length > 0) {
      let ans: any = [];
      quarterList.forEach((ele: any) => {
        ans.push({
          value: ele.id,
          label: ele.name,
        });
      });
      setQuaterListArr(ans);
      byDefaultSelectedQuater(quarterList);
    }
  }, [isActive, quarterList]);

  useEffect(() => {
    setSelectedDivisition([]);
    setSelectedSlm([]);
    setGrpOfToPool(undefined);
    setGrpOfFromPool(undefined);
    setDivisionListId([]);
    setDivision(undefined);
    setSLM(undefined);
    dispatch(incentiveActions.setSalesTransferData(null));
    dispatch(incentiveActions.setSLMData([]))
  }, [isActive]);

  const byDefaultSelectedQuater = (quarterList: any) => {
    try {
      if (quarterList.length > 0) {
        let temp: any = [];
        quarterList.forEach((ele: any) => {
          if (ele?.isCurrentQuarter) {
            temp.push(ele.name);
            setQuarter(ele);
          }
        });
        setSelectedQuater(temp);
        const quaterId = getIdListArr(temp, quaterListArr);
        setQuaterListId(quaterId);
      }
    } catch (error) {
      console.log('-------- error -----------', error);
    }
  };

  const getFiltersData = () => {
    return {
      staffPositionId: slm?.value ? slm?.value : 0,
      quarterId: quarter?.id ? quarter?.id : 0,
      toPoolId: singleValueToPool?.value ? singleValueToPool?.value : 0,
      toGroupPoolId: grpOfToPool?.value ? grpOfToPool?.value : 0,
      fromPoolId: singleValueFromPool?.value
        ? singleValueFromPool?.value
        : 0,
      fromGroupPoolId: grpOfFromPool?.value ? grpOfFromPool?.value : 0,
      searchKey: search?.query || '',
    }
  }

  const filterHandler = useCallback(
    async (e?: any) => {
      e?.preventDefault();
      if (isActive === 0) {
        const dataParam = getFiltersData();
        dispatch(fetchSalesTransferGoBtnCreator({val: 1}));
        dispatch(incentiveActions.setSelectedQuarter(quarter));
        dispatch(fetchSalesTransferDataCreator(dataParam));
      } else {
        dispatch(incentiveActions.setSalesTransferData(null));
        downloadData();
      }
    },
    [
      fromPool,
      slm,
      quarter,
      toPool,
      grpOfToPool,
      fromPool,
      grpOfFromPool,
      search,
      singleValueToPool,
      singleValueFromPool,
      isGoBtnDisble,
      isActive,
      divisionListId,
      quaterListId,
      staffPositionIdList,
    ],
  );

  useEffect(() => {
    if (
      refetchSalesTransfer &&
      isActive === 0
    ) {
      filterHandler();
      dispatch(incentiveActions.setRefetchSalesTransfer(false));
    }
  }, [refetchSalesTransfer]);

  const downloadData = (downloadAllData?: boolean) => {
    if (
      !downloadAllData &&
      (staffPositionIdList.length ||
        quaterListId.length ||
        divisionListId.length || grpOfFromPool?.value || grpOfToPool?.value)
    ) {
      callUploadApi();
    } else {
      setShowDownloadConfirmation(true);
    }
  };

  const callUploadApi = useCallback(
    async (downloadAllData?: boolean) => {
      setShowDownloadConfirmation(false);
      const grpOfFromPoolValue = grpOfFromPool?.value ? [grpOfFromPool?.value] : [];
      const grpOfToPoolValue = grpOfToPool?.value ? [grpOfToPool?.value] : [];
      const params = {
        staffPositionIdList: downloadAllData ? [] : staffPositionIdList,
        quarterList: downloadAllData ? [] : quaterListId,
        fromGroupPoolList: downloadAllData ? [] : grpOfFromPoolValue,
        toGroupPoolList: downloadAllData ? [] : grpOfToPoolValue,
        divisionList: downloadAllData ? [] : divisionListId,
      };
      setIsDownloading(true);
      await downloadExcelFile(
        API_PATH.incentiveConfiguration.fetchDownloadExcelSalesTransferURL,
        params,
      );
      setIsDownloading(false);
    },
    [
      staffPositionIdList,
      quaterId,
      grpOfToPool,
      grpOfFromPool,
      divisionListId,
      quaterListId,
    ],
  );

  useEffect(() => {
    if (isFilterIconStatus?.clickOnUploadBtn) {
      downloadData(true);
      dispatch(
        inactiveNavbaFilterIconCreator({
          clickOnUploadBtn: false,
        }),
      );
    }
  }, [isFilterIconStatus]);

  useEffect(() => {
    try {
      if (
        (search?.query?.length > 2 ||
          (search?.query?.length === 0 && prevSearchQuery.length)) &&
        salesTransferData
      ) {
        if(salesTransferData.isAuditTrailData) return;
        prevSearchQuery = search?.query;
        searchApiCall();
      }
    } catch (error) {
      console.log('-----sales transfer screen error-------', error);
    }
  }, [search]);

  const searchApiCall = () => {
    const dataParam = {
      staffPositionId: slm?.value ? slm?.value : 0,
      quarterId: quarter?.id ? quarter?.id : 0,
      toPoolId: singleValueToPool?.value ? singleValueToPool?.value : 0,
      toGroupPoolId: grpOfToPool?.value ? grpOfToPool?.value : 0,
      fromPoolId: singleValueFromPool?.value ? singleValueFromPool?.value : 0,
      fromGroupPoolId: grpOfFromPool?.value ? grpOfFromPool?.value : 0,
      searchKey: search?.query || '',
    };
    dispatch(fetchSalesTransferDataCreator(dataParam));
  };

  useEffect(() => {
    let toPoolTempArr: any[] = [];
    if (toPoolFilterData && toPoolFilterData?.length > 0) {
      toPoolFilterData?.forEach((val: any) => {
        toPoolTempArr.push(val?.label);
      });
      setToPool(toPoolTempArr);
    }
  }, [toPoolFilterData, grpOfFromPool, slm]);

  const handleGroupfromPool = useCallback(
    (val: any) => {
      if (val?.value) {
        const updatedTemp = toGroupOfPoolData.filter(
          (item: any) => item?.value !== val?.value,
        );
        setTogroupOfPoolListArr(updatedTemp);
        setGrpOfToPool(undefined);
      } else {
        setTogroupOfPoolListArr(toGroupOfPoolData);
      }
      setGrpOfFromPool(val || undefined);
      setFromPool([]);
      dispatch(
        fetchPoolCreator({
          StaffPositionId: slm?.value,
          QuarterId: quarter?.id,
          grouppoolid: val?.value,
          divisionId: division?.value,
          from: 'fromGroup',
        }),
      );
    },
    [
      toPoolList,
      grpOfFromPool,
      slm,
      quarter,
      grpOfToPoolList,
      togroupOfPoolListArr,
    ],
  );

  const handleToGroupOfPool = useCallback(
    (val: any) => {
      if (val?.value) {
        const updatedTemp = grpOfToPoolList?.filter(
          (item: any) => item.value !== val?.value,
        );
        setFromgroupOfPoolListArr(updatedTemp);
      } else {
        setFromgroupOfPoolListArr(grpOfToPoolList);
      }
      setGrpOfToPool(val || undefined);
      setToPool([]);
      dispatch(
        fetchPoolCreator({
          StaffPositionId: slm?.value,
          QuarterId: quarter?.id,
          grouppoolid: val?.value,
          divisionId: division?.value,
          from: 'toGroup',
        }),
      );
    },
    [
      grpOfFromPool,
      slm,
      quarter,
      grpOfToPoolList,
      fromgroupOfPoolListArr,
      dispatch,
    ],
  );

  useEffect(() => {
    if (fromPoolFilterData?.length > 0) {
      fromPoolFilterData.forEach((val: any) => {
        tempArr.push(val?.label);
      });
      setFromPool(tempArr);
    }
  }, [fromPoolFilterData, tempArr]);

  const getIdListArr = (listName: string[], data: any) => {
    const ans = data.reduce((acc: any, obj: any) => {
      if (listName.length > 0) {
        listName.forEach((val: any) => {
          if (val === obj.label) {
            acc.push(obj.value);
          }
        });
      }
      return acc;
    }, []);
    return ans;
  };

  const handleDivision = (val: any) => {
    let divisionId = [val?.value];
    dispatch(fetchSLMCreator(divisionId));
    setDivision(val || undefined);
    setSLM(undefined);
    setToPool(undefined);
    setGrpOfToPool(undefined);
    setFromPool(undefined);
    setGrpOfFromPool(undefined);
  };

  const handleMultiSelectionDivision: any = useCallback(
    (e: string[]) => {
      setSelectedDivisition(e);
      if (divisionList?.length > 0 && e.length > 0) {
        let divisionIdListArr = getIdListArr(e, divisionList);
        setDivisionListId(divisionIdListArr);
        dispatch(fetchSLMCreator(divisionIdListArr));
      } else {
        const allDivisionids = divisionList.map((d: any) => d.value);
        dispatch(fetchSLMCreator(allDivisionids));
        setDivisionListId([]);
      }
      setResetSlmDropdown(1);
      setTimeout(() => {
        setResetSlmDropdown(false);
      }, 500);
    },
    [dispatch, divisionList, divisionListId],
  );

  const handleQuater = useCallback(
    (val: any) => {
      setSelectedQuater(val);
      let quaterId: number[] = [];
      if (quaterListArr?.length > 0 && val?.length > 0) {
        quaterId = getIdListArr(val, quaterListArr);
        setQuaterListId(quaterId);
      } else {
        setQuaterListId(quaterId);
      }
      if(JSON.stringify(quaterListId) !== JSON.stringify(quaterId)) {
        fetchDownloadGroupOfPools(staffPositionIdList, quaterId)
        setResetSlmDropdown(1);
        setTimeout(() => {
          setResetSlmDropdown(false);
        }, 500);
      }
    },
    [quaterListArr, quaterListId, staffPositionIdList, selectedQuater],
  );

  const handleSlm = useCallback(
    (val: any, isReset?: boolean) => {
      setSelectedSlm(val);
      let slmId = getIdListArr(val, slmList);
      setStaffPositionIdList(slmId);
      setGrpOfFromPool(undefined);
      setGrpOfToPool(undefined);
      dispatch(incentiveActions.setFromPoolData([]));
      dispatch(incentiveActions.setToGroupOfpool([]));
      if(!isReset)
        fetchDownloadGroupOfPools(slmId);
      
    },
    [slmList, selectedSlm, quaterListId, staffPositionIdList],
  );

  const fetchDownloadGroupOfPools = (selectedSlmIds: any, selectedQuarterIds?: any) => {
    const allSlmIds = slmList.map((s: any) => s.value);
    const slmIds = selectedSlmIds.length ? selectedSlmIds : allSlmIds;
    if(!slmIds.length) return;
    dispatch(
      fetchfromPoolCreator({
        staffPositionIdList: slmIds,
        quarterList: selectedQuarterIds || quaterListId,
        fetchToGroupsPools: true,
      }),
    );
  }

  const disableTGFP = useCallback(() => {
    let flag = false;

    if (isActive === 0 && (division === undefined || slm === undefined)) {
      flag = true;
    }

    return flag;
  }, [isActive, division, slm, selectedDivisition, selectedSlm]);

  const handleDownloadExcelTemplate = async () => {
    const params = {
      staffPositionId: slm?.value ? slm?.value : 0,
      quarterId: quarter?.id,
      toGroupPoolId: grpOfToPool?.value,
      fromGroupPoolId: grpOfFromPool?.value,
      searchKey: '',
    };
    setIsDownloading(true);
    await downloadExcelFile(
      API_PATH.incentiveConfiguration.salesTransferExcelTemplateDownload,
      params,
    );
    setIsDownloading(false);
  }

  return (
    <>
      <div style={Styles.filterHeaderBody}>
        <div style={Styles.filterBox}>
          <Grid container spacing={1}>
            <Grid item xs={2.1}>
              <Box style={Styles.text}>DIVISION</Box>
              <Box style={Styles.inputCell}>
                <If condition={isActive === 0}>
                  <Dropdown
                    width={600}
                    onChange={(val: any) => handleDivision(val)}
                    value={division}
                    disabled={false}
                    dropdownList={divisionList || []}
                    placeholder="Select Division"
                  />
                </If>
                <If condition={isActive === 1}>
                  <MultiSelectDropDown
                    dropdownWidth={140}
                    width={'100%'}
                    isSearchable
                    options={divisionList}
                    placeholder="Select Division"
                    selectedValues={(e: string[]) =>
                      handleMultiSelectionDivision(e)
                    }
                    defaultSelected={selectedDivisition || []}
                  />
                </If>
              </Box>
            </Grid>

            <Grid item xs={2.1}>
              <Box style={Styles.text}>QUARTER</Box>
              <Box style={Styles.inputCell}>
                <If condition={isActive === 0}>
                  <Dropdown
                    width={600}
                    onChange={(val: any) => {
                      setQuarter(val || undefined);
                      setSLM(undefined);
                      setToPool(undefined);
                      setGrpOfToPool(undefined);
                      setFromPool(undefined);
                      setGrpOfFromPool(undefined);
                    }}
                    value={quarter}
                    dropdownList={quarterList || []}
                    placeholder="Quarter"
                    // disabled={}
                  />
                </If>
                <If condition={isActive === 1}>
                  <MultiSelectDropDown
                    dropdownWidth={140}
                    width={'100%'}
                    isSearchable
                    options={quaterListArr}
                    placeholder="Quarter"
                    selectedValues={(e: string[]) => handleQuater(e)}
                    defaultSelected={selectedQuater}
                  />
                </If>
              </Box>
            </Grid>

            <Grid item xs={2.1}>
              <Box style={Styles.text}>SLM</Box>
              <Box style={Styles.inputCell}>
                <If condition={isActive === 0}>
                  <Dropdown
                    width={600}
                    onChange={(val: any) => {
                      setSLM(val || undefined);
                      setToPool(undefined);
                      setGrpOfToPool(undefined);
                      setFromPool(undefined);
                      setGrpOfFromPool(undefined);
                      setSingleValueFromPool(undefined);
                      setSingleValueToPool(undefined);
                      dispatch(incentiveActions.setToPoolData([]));
                      dispatch(incentiveActions.setPoolData([]));
                      dispatch(incentiveActions.setFromPoolData([]));
                      dispatch(incentiveActions.setToGroupOfpool([]));
                      if (val) {
                        dispatch(
                          fetchPoolCreator({
                            StaffPositionId: val?.value,
                            divisionId: division?.value,
                            QuarterId: quarter?.id,
                            grouppoolid: 0,
                          }),
                        );
                        dispatch(
                          fetchfromPoolCreator({
                            staffPositionIdList: [val?.value],
                            quarterList: [quarter?.id],
                            fetchToGroupsPools: true,
                          }),
                        );
                      }
                    }}
                    value={slm}
                    dropdownList={slmList || []}
                    placeholder="Select SLM"
                    disabled={!division || !quarter}
                  />
                </If>
                <If condition={isActive === 1}>
                  <MultiSelectDropDown
                    dropdownWidth={130}
                    width={'100%'}
                    isSearchable
                    options={slmList}
                    placeholder="Select SLM"
                    selectedValues={(e: string[], isReset?: boolean) => handleSlm(e, isReset)}
                    defaultSelected={selectedSlm}
                    reset={resetSlmDropdown}
                    // disabled={!divisionListId.length}
                  />
                </If>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                style={{
                  fontSize: 12,
                  color: '#322b7c',
                  fontWeight: '600',
                  marginBottom: 10,
                }}>
                FROM GRP OF POOL
              </Box>
              <Box style={Styles.inputCell}>
                {/* <If condition={isActive === 0}> */}
                <Dropdown
                  width={530}
                  onChange={handleGroupfromPool}
                  value={grpOfFromPool}
                  dropdownList={fromgroupOfPoolListArr || []}
                  placeholder="Grp Of Pool"
                  disabled={disableTGFP()}
                />
                {/* </If> */}
                {/* <If condition={isActive === 1}>
                <MultiSelectDropDown
                  width={135}
                  options={fromgroupOfPoolListArr}
                  placeholder="Grp Of Pool"
                  selectedValues={(e: string[]) => handleGroupOfPoolForMultisection(e)}
                  defaultSelected={selectedGFP}
                />
              </If> */}
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box style={Styles.text}>TO GRP OF POOL</Box>
              <Box style={Styles.inputCell}>
                <Dropdown
                  width={500}
                  onChange={handleToGroupOfPool}
                  value={grpOfToPool}
                  dropdownList={togroupOfPoolListArr || []}
                  placeholder="To group of pool"
                  disabled={disableTGFP()}
                />
                {/* <If condition={isActive === 1}>
                <MultiSelectDropDown
                  width={135}
                  options={togroupOfPoolListArr || []}
                  placeholder="To group of pool"
                  selectedValues={(e: string[]) => handleToGroupOfPoolForMultisection(e)}
                  defaultSelected={selectedTGFP}
                />
              </If> */}
              </Box>
            </Grid>
            <Grid
              item
              // xs={2.5}
              sx={{display: 'flex', alignItems: 'center', marginTop: 3}}>
              <CustomButton
                disabled={isActive === 0 ? isGoBtnDisble : false}
                data-testid="clickGo-icon"
                variant="contained"
                onClick={filterHandler}
                size="small"
                style={{
                  fontFamily: ['Poppins'].join(','),
                  fontSize: 12.7,
                  cursor: 'pointer',
                  letterSpacing: 0,
                  textAlign: 'center',
                  backgroundColor:
                    isGoBtnDisble && isActive === 0 ? '#e0e0e0' : '#322b7c',
                  color: 'white',
                  border: 0,
                  borderRadius: 10,
                  minWidth: 50,
                }}>
                {isActive === 0 ? 'Go' : 'Download'}
              </CustomButton>
            </Grid>
          </Grid>
        </div>
      </div>
      {isDownloading ? (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '1vh',
          }}>
          <CircularProgress
            sx={{color: 'primary.main', margin: '0 auto'}}
            disableShrink
          />
        </Box>
      ) : null}
      <ConfirmationDialog
        open={showDownloadConfirmation}
        onClose={(status: boolean) => {
          setShowDownloadConfirmation(false);
          if (status) callUploadApi(true);
        }}
        message="You have requested to download the complete data for current financial year. Do you want to Continue?"
      />
    </>
  );
}

export default React.memo(FilterHeader);
